<template>
  <div :class="'col-' + chart.width">
    <div
      class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <div class="flex justify-content-between align-items-center mb-1">
        <h2 class="font-normal text-black-alpha-90 my-3">{{ chart.title }}</h2>

        <div>
          <Dropdown
            v-if="chart.relative"
            v-model="dataset"
            :options="
              chart.relative
                ? [
                    { n: 'Absolute', v: 'absolute' },
                    { n: 'Relative', v: 'relative' },
                  ]
                : [{ n: 'Absolute', v: 'absolute' }]
            "
            option-value="v"
            option-label="n"
            placeholder="Dataset"
          />
          <p v-if="!chart.relative" class="mb-3">Absolute</p>
        </div>
      </div>

      <div class="py-5 text-center" v-if="loading">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>

      <div class="chart" v-if="chartData">
        <Chart
          :type="chart.type"
          :data="chartData"
          :options="chartOptions"
          responsive="true"
          width="375"
          height="375"
        />
      </div>

      <hr />

      <!-- <div class="text-black-alpha-40">Updated less than an hour ago</div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChartCard",
  props: {
    chart: {
      type: Object,
      required: true,
    },
    mailingId: {
      type: Number,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dataset: this.chart && this.chart.relative ? "relative" : "absolute",
      chartResponse: null,
      loading: false,
      error: true,
      chartApiVersion: this.chart.version,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
            hoverBackgroundColor: [],
          },
        ],
      },
    };
  },
  watch: {
    mailingId() {
      this.loadChartData();
    },
    dataset() {
      this.setChartData();
    },
    chart() {
      this.setChartData();
    },
  },
  computed: {
    chartOptions() {
      let dataset = this.dataset;
      return {
        indexAxis: this.horizontal ? "y" : "x",
        scaleShowValues: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
        },

        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks:
              dataset === "absolute"
                ? {}
                : {
                    label(context) {
                      return (
                        context.label + ": " + context.formattedValue + " %"
                      );
                    },
                  },
          },
          legend: {
            display: this.chart.type !== "bar",
          },
        },
      };
    },
  },
  mounted() {
    if (this.chart) this.loadChartData();
  },
  methods: {
    loadChartData() {
      this.loading = true;
      this.error = false;

      //Assuming mailing_id == 100, URL will translate to /chart/v1/simple/lead/r.custom_string_1?mailing_id=100
      //Variable subsituion URL  would be /chart/v{version}/{style}/{source}/{field}

      let url =
        "/chart/v" +
        this.chartApiVersion +
        "/" +
        this.chart.style +
        "/" +
        this.chart.source +
        "/" +
        this.chart.field;

      axios
        .get(url, { params: { mailing_id: JSON.stringify(this.mailingId) } })
        .then((response) => {
          this.chartResponse = response.data.data;
          this.setChartData();
          console.log(this.chartResponse);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .then(() => (this.loading = false));
    },
    setChartData() {
      if (this.dataset === "relative") {
        this.chartData.datasets[0].data = this.chartResponse.chartData
          .filter(
            //Filter out bad responses
            (data) =>
              this.chartResponse.mailingStats[data.value] &&
              this.chartResponse.mailingStats[data.value].mailed
          )
          .map(
            (data) =>
              (data.aggregate * 100) /
              this.chartResponse.mailingStats[data.value].mailed
          );
      } else {
        this.chartData.datasets[0].data = this.chartResponse.chartData.map(
          (data) => data.aggregate
        );
      }

      this.chartData.datasets[0].backgroundColor =
        this.chartResponse.colorScheme;

      this.chartData.datasets[0].hoverBackgroundColor =
        this.chartResponse.colorScheme.map((color) =>
          this.adjustColor(color, -30)
        );
      //this.chartResponse.colorScheme;

      this.chartData.labels = this.chartResponse.chartData.map(
        (data) => data.value
      );
    },
    adjustColor(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
  },
};
</script>

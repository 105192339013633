<template>
  <div class="grid">
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Mailed</span>
            <div class="text-900 font-medium text-xl" v-if="mailing.mailed">
              {{ $filters.numberThousandSeparator(mailing.mailed.total) }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[0] }"
          >
            <i class="pi pi-send text-white text-lg"></i>
          </div>
        </div>
        <span class="text-500">&nbsp;</span>
      </div>
    </div>
    <div
      class="col-12 lg:col-6"
      :class="xlColSize"
      v-if="hasDelivered && colorScheme.length > 0"
    >
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Delivered</span>
            <div class="text-900 font-medium text-xl" v-if="mailing.delivered">
              {{ $filters.numberThousandSeparator(mailing.delivered.total) }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[1] }"
          >
            <i class="pi pi-home text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{ $filters.numberFourDecimal(deliveryPercentage) }}%</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Responded</span>
            <div class="text-900 font-medium text-xl" v-if="mailing.web_visits">
              {{ $filters.numberThousandSeparator(mailing.web_visits.total) }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[2] }"
          >
            <i class="pi pi-users text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{ $filters.numberFourDecimal(responsePercentage) }}%</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Converted</span>
            <div class="text-900 font-medium text-xl" v-if="mailing.web_leads">
              {{ $filters.numberThousandSeparator(mailing.web_leads.total) }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[3] }"
          >
            <i class="pi pi-user-plus text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{ $filters.numberFourDecimal(conversionPercentage) }} %</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app";
export default {
  name: "RollingStats",
  props: {
    mailing: {
      type: Object,
      default: null,
    },
    source: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return { colorScheme: this.appStore.colorScheme };
  },
  computed: {
    responsePercentage() {
      return this.mailing && this.mailing.web_leads && this.mailing.mailed
        ? (this.mailing.web_visits.total * 100) / this.mailing.mailed.total
        : 0;
    },
    deliveryPercentage() {
      return this.hasDelivered
        ? (this.mailing.delivered.total * 100) / this.mailing.mailed.total
        : 0;
    },
    conversionPercentage() {
      return this.mailing && this.mailing.web_leads && this.mailing.web_visits
        ? (this.mailing.web_leads.total * 100) / this.mailing.web_visits.total
        : 0;
    },
    hasDelivered() {
      return (
        this.mailing &&
        this.mailing.delivered &&
        this.mailing.delivered.total > 0
      );
    },
    xlColSize() {
      return this.hasDelivered ? "xl:col-3" : "xl:col-4";
    },
  },
};
</script>

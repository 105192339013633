<template>
  <TitleBar>Response Dashboard for {{ mailing_name }}</TitleBar>
  <div class="px-5 pb-5">
    <RollingStats
      :mailing="mailingOverview"
      v-if="mailingOverview"
      :source="true"
    />

    <div class="grid" v-if="chartsLayout && chartsLayout">
      <ChartCard
        v-for="chart in chartsLayout"
        :key="chart.field"
        :chart="chart"
        :mailing-id="$route.params.mailing_id"
        :horizontal="chart.horizontal || false"
      />
    </div>
  </div>
</template>

<script>
import ChartCard from "@/components/ChartCard";
import CampaignOverview from "@/models/CampaignOverview";
import RollingStats from "@/components/mailingOverview/RollingStats";
import TitleBar from "@/components/TitleBar";
import ChartLayout from "@/models/ChartLayout";
import { useAppStore } from "@/stores/app";

export default {
  components: { TitleBar, RollingStats, ChartCard },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      chartsLayout: null,
      loading: false,
      mailingOverview: null,
      mailing_name: null,
    };
  },
  async mounted() {
    await this.loadChartLayouts();
    this.loadMailingOverview();
  },
  computed: {
    campaign() {
      return this.appStore.currentCampaign;
    },
  },
  methods: {
    async loadChartLayouts() {
      //this.loading = true;
      await ChartLayout.where("campaign_id", this.campaign.id)
        .where("type", "mailing")
        .limit(1)
        .first()
        .then((data) => {
          console.log(data.charts);
          this.chartsLayout = JSON.parse(data.charts);
        });
    },
    loadMailingOverview() {
      this.loading = true;

      CampaignOverview.where("mailing_id", this.$route.params.mailing_id)
        .find(this.campaign.id)
        .then((res) => {
          this.mailingOverview = res.data;
          //console.log(res.data.included_mailings[0]);
          this.mailing_name =
            res.data.included_mailings[0].formatted_description;
        })
        .catch((error) => {
          // TODO: handle different type of errors based on the err type.
          // Show error toast
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 2000,
          });
          if (error.response.status === 403) {
            this.$router.push({ name: "dashboard" });
          }
        })
        .then(() => (this.loading = false));
    },
  },
  watch: {
    $route(val) {
      if (typeof val.params.mailing_id == "undefined") return;
      this.loadMailingOverview();
    },
  },
};
</script>
